import React from 'react'

import Navigation from 'utils/navigation'
import { Paths } from 'utils/paths'
import KeysActions from 'actions/keys'
import SessionActions from 'actions/session'
import OutsideClickDetector from '../Mixins/OutsideClickDetector'

import adafruitSmallLogo from 'images/logos/adafruit-small-logo.png'

export default class MobileHeader extends React.Component {

  buttonRef = React.createRef()

  state = {
    dropdownVisible: false
  }

  showMobileMenu = () => {
    document.body.classList.add('mobile-nav-active')
    this.setState({ dropdownVisible: true })
  }

  hideMobileMenu = () => {
    document.body.classList.remove('mobile-nav-active')
    this.setState({ dropdownVisible: false })
  }

  toggleDropdown = () => {
    this.state.dropdownVisible
      ? this.hideMobileMenu()
      : this.showMobileMenu()
  }

  logOut = () => {
    return this.props.dispatch(SessionActions.logoutAndRedirect())
  }

  navigateTo(path) {
    this.hideMobileMenu()
    Navigation.go(path)
  }

  linkTo(href, text) {
    return (
      <a href={href} onClick={evt => {
        evt.preventDefault()
        this.navigateTo(href)
      }}>{text}</a>
    )
  }

  internalNavigation() {
    const { user } = this.props
    if (user) {
      const { username } = user
      return (
        <>
          <li>{ this.linkTo(Paths.profile({ username }), "Profile") }</li>
          <li>{ this.linkTo(Paths.devices({ username }), "Devices") }</li>
          <li>{ this.linkTo(Paths.feeds({ username }), "Feeds") }</li>
          <li>{ this.linkTo(Paths.dashboards({ username }), "Dashboards") }</li>
          <li>{ this.linkTo(Paths.actions({ username }), "Actions") }</li>
          <li>{ this.linkTo(Paths.services({ username }), "Power-Ups") }</li>
          <li>
            <a href="#" onClick={() => this.props.dispatch(KeysActions.toggleModal(true))}>View Adafruit IO Key</a>
          </li>
        </>
      )
    }
  }

  applicationLinks() {
    return (
      <nav aria-label="Adafruit applications menu">
        <ul className="application-links">
          <li><a aria-label="Adafruit Shop" href="https://www.adafruit.com">Shop</a></li>
          <li><a aria-label="Adafruit Learning System" href="https://learn.adafruit.com">Learn</a></li>
          <li><a aria-label="Adafruit Blog" href="https://blog.adafruit.com">Blog</a></li>
          <li><a aria-label="Adafruit Forums and Support" href="https://forums.adafruit.com">Forums</a></li>
            <li className="selected"><a onClick={(evt) => evt.preventDefault()} aria-label="Adafruit IO, the internet of things" href="https://io.adafruit.com">IO</a></li>
          <li><a aria-label="Adafruit Youtube Videos" href="https://www.youtube.com/adafruit/live">LIVE!</a></li>
          <li><a aria-label="Adafruit product box subscription" href="https://www.adabox.com">AdaBox</a></li>
        </ul>
      </nav>
    )
  }

  accountInfo() {
    const { user } = this.props

    if (user) {
      return (
        <>
          <div className="welcome-message">
            Hello, {user.name}
          </div>
          <a href={import.meta.env.VITE_ACCOUNTS_URL}>My Account</a>
          <a href="#" onClick={this.logOut}>Sign Out</a>
        </>
      )
    } else {
      return (
        <>
          <a href={import.meta.env.VITE_IO_SIGN_UP_URL}>Get Started for Free</a>
          <a href={import.meta.env.VITE_IO_SIGN_IN_URL}>Sign In</a>
        </>
      )
    }
  }

  render() {
    return (
      <div id='small-header'>
        <div className="first-row">
          { this.applicationLinks() }
        </div>

        <div className="second-row">
          <div className="row-content">
            <div id="mobile-header-logo">
              <a href="https://io.adafruit.com">
                <img id="small-logo" height="50" width="50" alt="Adafruit Logo" src={adafruitSmallLogo} />
              </a>
            </div>
            <button ref={ this.buttonRef } id="mobile-menu-button" className="c-hamburger c-hamburger--htx" onClick={ this.toggleDropdown }>
              <span>toggle menu</span>
            </button>
            <div id="mobile-search-wrapper">
            </div>
            <a id="mobile-header-cart" href="https://www.adafruit.com/shopping_cart">
              <span className="small-cart-count">{this.props.cartCount}</span>
              <i className="fa fa-shopping-cart"></i>
            </a>
          </div>
        </div>

        <nav id="small-header-nav">
          <OutsideClickDetector onOutsideClick={ this.hideMobileMenu } ignoreOtherTargets={ [ this.buttonRef ] }>
            <div id="small-menu">
              <ul>
                <li className='account-info'>
                  { this.accountInfo() }
                </li>
                { this.internalNavigation() }
              </ul>
            </div>
          </OutsideClickDetector>
        </nav>
      </div>
    )
  }
}
